import {
  type DocumentData,
  getDoc,
  getDocs,
  type PartialWithFieldValue,
  query,
  QueryDocumentSnapshot,
  documentId,
  limit,
  serverTimestamp,
  updateDoc,
  where
} from 'firebase/firestore'
import type { AppUser, AproData, Medication, WelcomeGift } from '~/models'

export default function useAppUsers() {
  const { collection, collectionGroup, doc } = useFirebase()

  function searchQueryEmail(searchQuery: string) {
    return query(
      collection('AppUsers'),
      where('ProfileDataMap.Email', '>=', searchQuery),
      where('ProfileDataMap.Email', '<=', `${searchQuery}\uF8FF`),
      limit(50)
    )
  }

  function searchQueryGuid(searchQuery: string) {
    return query(
      collection('AppUsers'),
      where(documentId(), '>=', searchQuery),
      where(documentId(), '<=', `${searchQuery}\uF8FF`),
      limit(50)
    )
  }

  function searchQueryPatientId(searchQuery: string) {
    return query(
      collectionGroup('ExternalUserData'),
      where('PatientId', '>=', searchQuery),
      where('PatientId', '<=', `${searchQuery}\uF8FF`),
      limit(50)
    )
  }

  function searchQueryBsn(searchQuery: string) {
    return query(
      collection('AppUsers'),
      where('ProfileDataMap.BSN', '>=', searchQuery),
      where('ProfileDataMap.BSN', '<=', `${searchQuery}\uF8FF`),
      limit(50)
    )
  }

  function searchQueryName(searchQuery: string) {
    return query(
      collection('AppUsers'),
      where('ProfileDataMap.firstName', '>=', searchQuery),
      where('fullName', '<=', `${searchQuery}\uF8FF`),
      limit(50)
    )
  }

  async function processAppUsersQuerySnapshots(
    querySnapshots: QueryDocumentSnapshot<DocumentData>[],
    searchBy?: 'patientId'
  ): Promise<AppUser[]> {
    return await Promise.all(
      querySnapshots.map(async (document) => {
        let userData: DocumentData = document.data()
        let userId = document.id
        let userRef = document.ref
        let aproData

        if (searchBy === 'patientId' && document.ref.parent.parent) {
          // In case search is done by patientId, we need to get the user data from the parent document
          userId = document.ref.parent.parent.id
          userRef = document.ref.parent.parent

          userData = (await getAppUserData(
            document.ref.parent.parent.id
          )) as DocumentData
          aproData = document.data()
        } else {
          aproData = (await getExternalUserAproData(
            document.id
          )) as DocumentData
        }
        console.log('userdData', userData)
        return {
          id: userId,
          ref: userRef,
          ...userData,
          ExternalUserData: {
            AproData: aproData
          }
        } as AppUser
      })
    )
  }

  async function getAppUserData(userId: string): Promise<AppUser | null> {
    const appUser = await getDoc(doc<AppUser>('AppUsers', userId))

    if (appUser.exists()) {
      return {
        ...appUser.data(),
        id: appUser.id,
        ref: appUser.ref
      } as AppUser
    }

    return null
  }

  async function updateAppUser(
    appUserId: string,
    payload: PartialWithFieldValue<AppUser>
  ): Promise<void> {
    return updateDoc<AppUser, DocumentData>(doc(`AppUsers/${appUserId}`), {
      ...payload,
      Updated: serverTimestamp()
    })
  }

  async function getExternalUserAproData(
    userId: string
  ): Promise<AproData | null> {
    const externalAproData = await getDoc(
      doc('AppUsers', userId, 'ExternalUserData', 'AproData')
    )

    if (externalAproData.exists()) return externalAproData.data() as AproData
    return null
  }

  async function getUserAndAproData(userId: string): Promise<AppUser | null> {
    if (!userId) {
      return null
    }
    const user = await getAppUserData(userId)
    const userAproData = await getExternalUserAproData(userId)

    let userAproDataValues = {}
    if (userAproData) {
      userAproDataValues = {
        ExternalUserData: {
          AproData: userAproData as AproData
        }
      }
    }

    return {
      ...(user as AppUser),
      ...userAproDataValues
    } as AppUser
  }

  function getAddresses(userDetails: AppUser) {
    const userAddresses = userDetails.ProfileDataMap?.Addresses
    if (!userAddresses) return []

    // Only return an address if it has the "Enabled" property as we don't want to return empty address objects
    const enabledAddresses = userAddresses.filter((address) =>
      address.hasOwnProperty('Enabled')
    )

    return enabledAddresses?.map((address, index) => ({
      ...address,
      Enabled: index === enabledAddresses.length - 1
    }))
  }

  function getGenderLabel(gender?: string | undefined) {
    if (gender === 'female') return 'V'
    if (gender === 'male') return 'M'
    return ''
  }

  async function getWelcomeGift(
    welcomeGiftTitle: string
  ): Promise<WelcomeGift | null> {
    const queryWelcomeGift = query(
      collection('WelcomeGifts'),
      where('Title', '==', welcomeGiftTitle)
    )

    const { docs } = await getDocs(queryWelcomeGift)
    if (docs.empty) return null

    const document = docs[0]
    return {
      ...document.data(),
      id: document.id,
      ref: document.ref
    } as WelcomeGift
  }

  function aproLink(userId?: string | undefined) {
    if (!userId) return undefined
    return `https://apotheek.sanday.com/APRO_PatientCare/Entry_PatientShow.aspx?PatientId=${userId}&PreviousPageURL=&PatientShowTabId=0&DistributionGroupId=0&AdministrationStopRequestId=0&IterationRequestId=0&HasFeedbackMessage=False&InvoiceToDownloadId=0&DispenseEventId_Scanned=0`
  }

  async function getAppUserMedications(
    appUserId: string
  ): Promise<Medication[]> {
    if (!appUserId) return []

    const userMedicationsQuery = query(
      collection(
        'AppUsers',
        appUserId,
        'ExternalUserData',
        'AproData',
        'Medications'
      )
    )

    const { docs } = await getDocs(userMedicationsQuery)
    return Promise.all(docs.map(async (doc: DocumentData) => doc.data()))
  }

  return {
    aproLink,
    getAddresses,
    getAppUserData,
    getAppUserMedications,
    getExternalUserAproData,
    getGenderLabel,
    getUserAndAproData,
    getWelcomeGift,
    processAppUsersQuerySnapshots,
    searchQueryEmail,
    searchQueryGuid,
    searchQueryPatientId,
    searchQueryBsn,
    searchQueryName,
    updateAppUser
  }
}
